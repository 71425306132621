import React from 'react';
import { useMutation, gql } from '@apollo/client';


const FriendReq = (props) => {

    const Change_Friend_Status = gql`
        mutation ChangeFriendshipStatus($changeFriendshipStatus: ChangeFriendshipStatusInput!) {
            changeFriendshipStatus(changeFriendshipStatus: $changeFriendshipStatus) {
                code
                message
            }
        }
    `;

    const [handleClick] = useMutation(Change_Friend_Status, {
        variables: { changeFriendshipStatus: { user_id: props.id }
        },
        onCompleted: ({ changeFriendshipStatus }) => {
            console.log( changeFriendshipStatus.message );
        }
    });

    // delete request mutation
    const Delete_Friend_Req = gql`
      mutation DeleteFriendReq($friendRequest: FriendRequestInput!) {
        deleteFriendReq(friendRequest: $friendRequest) {
          message
        }
      }
    `;

    // delete request button handler
    const [requestDelete] = useMutation(Delete_Friend_Req, {
        variables: { friendRequest: { user_id: props.id }
        },
        onCompleted: ({ deleteFriendReq }) => {
            console.log( deleteFriendReq.message );
        }
    });


    return (
    <div>
      <button onClick={handleClick}>Accept</button>
      <button onClick={requestDelete}>delete</button>
    </div>
  );
};

export default FriendReq;
