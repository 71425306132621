import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import ArticleWebList from './components/ArticleWebList/ArticleWebList';
import ArticleList from './components/ArticleList/ArticleList';
import Chats from './components/Chats/Chats';
import CreateArticle from './components/CreateArticle/CreateArticle';
import Login from './components/Login/Login';
import Profile from './components/Profile/Profile';
import PublicChats from './components/PublicChats/PublicChats';
import ServiceList from './components/ServiceList/ServiceList';
import Users from './components/Users/Users';
import Menu from "./components/Menu/Menu";
import FriendRequests from "./components/FriendRequests/FriendRequests";
import ImageUpload from './components/ImageUpload/ImageUpload';

import './App.css';
import logo from './images/logo.svg';
import logoSmall from './images/cryptomoto-sign.svg';
import svgTwitter from './images/twitter.svg';
import svgInstagram from './images/instagram.svg';
import svgFacebook from './images/facebook.svg';
import svgLinkedIn from './images/linkedin-in.svg';
import svgTelegram from './images/telegram.svg';
import svgTikTok from './images/tik-tok.svg';
import svgYouTube from './images/youtube.svg';
import svgAppleStore from './images/app-store.svg';
import svgGooglePlay from './images/google-play.svg';


/*
const GET_DOGS = gql`
  query GetDogs {
    users {
      id
      first_name
    }
  }
`;

function Dogs({ onDogSelected }) {

  const handleClick = (event) => {
    event.preventDefault();
    const userSelect = document.getElementById('user-id').value;
    return userSelect;
  }

  const { loading, error, data } = useQuery(GET_DOGS);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <select name='userSelect' id='user-id' onChange={onDogSelected}>
        {data.users.map((user) => (
          <option key={user.id} value={user.id}>
            {user.first_name}
          </option>
        ))}
      </select>
      <button onClick={handleClick}>Refresh</button>
    </div>
  );
}

const GET_DOG_PHOTO = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      first_name
      email
      id
    }
  }
`;

function DogPhoto( {handleClick} ) {
  const { loading, error, data } = useQuery(GET_DOG_PHOTO, {
    variables: { userId: 2 },
    //pollInterval: 500,
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <div>
          <span>{data.user.email}</span>
          <span key={data.user.id} value={data.user.id}>
            {data.user.first_name}
          </span>
    </div>
  );
}
*/


export default function App() {

  return (
    <div className="App">
      <div className='top'>
        <header className="App-header">
          <a href="/">
            <img src={logo} className="App-logo" alt="CryptoMoto Web App" />
          </a>
          <Header />
          <div className="app-download">
            <a href="https://play.google.com/store/apps/details?id=org.cryptomoto.app" target="_blank" rel="noopener">
              <img src={svgGooglePlay} alt="Download the best mobile app for motorcyclists and bikers on the Google Play" height="60" />
            </a>
            <a href="https://apps.apple.com/us/app/cryptomoto/id6450195510" target="_blank" rel="noopener">
              <img src={svgAppleStore} alt="Download the best mobile app for motorcyclists and bikers on the Apple Store" height="60" />
            </a>
          </div>
        </header>
      </div>
      <section>
        <h1>CryptoMoto Web App 🏍️</h1>
          <a
              className="App-link"
              href="https://cryptomoto.org"
              target="_blank"
              rel="noopener noreferrer"
          >
            Learn more about CryptoMoto
          </a>        
        <Routes>
            <Route path="/" element={<ArticleWebList/>} />
            <Route path="/chats" element={<Chats/>} />
            <Route path="/create" element={<CreateArticle/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/public-chats" element={<PublicChats/>} />
            <Route path="/services" element={<ServiceList/>} />
            <Route path="/users" element={<Users/>} />
            <Route path="/menu" element={<Menu/>} />
            <Route path="/friend-requests" element={<FriendRequests/>} />
            <Route path="/upload" element={<ImageUpload/>} />
        </Routes>
      </section>
      <footer>
          <div className="privacy">
            <a href="https://cryptomoto.org/" aria-label="CryptoMoto" target="_blank">
              <img src={logoSmall} className="copyright-logo" alt="CryptoMoto Web App" />
            </a>
            © 2022-2024 <a href="https://cryptomoto.org/" rel="home" target="_blank">CryptoMoto</a>. All rights reserved.
          </div>
          <div className="copyrights">
            <nav aria-label="Social menu">
              <ul>
                <li>
                  <a title="Subscribe to CryptoMoto Twitter" target="_blank" rel="noopener" href="https://twitter.com/CryptoMotoApp">
                    <img src={svgTwitter} alt="Subscribe to CryptoMoto Twitter" />
                    <span>Twitter</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto Instagram" target="_blank" rel="noopener" href="https://www.instagram.com/crypto.moto.app/">
                    <img src={svgInstagram} alt="Subscribe to CryptoMoto Instagram" />
                    <span>Instagram</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto Facebook" target="_blank" rel="noopener" href="https://www.facebook.com/cryptomotoap">
                    <img src={svgFacebook} alt="Subscribe to CryptoMoto Facebook" />
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto LinkedIn" target="_blank" rel="noopener" href="https://www.linkedin.com/company/cryptomoto-ltd/">
                    <img src={svgLinkedIn} alt="Subscribe to CryptoMoto LinkedIn" />
                    <span>LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto TikTok" target="_blank" rel="noopener" href="https://www.tiktok.com/@cryptomotoapp">
                    <img src={svgTikTok} alt="Subscribe to CryptoMoto TikTok" />
                    <span>TikTok</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto YouTube" target="_blank" rel="noopener" href="https://www.youtube.com/@cryptomoto">
                    <img src={svgYouTube} alt="Subscribe to CryptoMoto YouTube" />
                    <span>YouTube</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto Telegram" target="_blank" rel="noopener" href="https://t.me/cryptomotoapp">
                    <img src={svgTelegram} alt="Subscribe to CryptoMoto Telegram" />
                    <span>Telegram</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto Facebook Group" target="_blank" rel="noopener" href="https://www.facebook.com/groups/cryptomotoapp">
                    <img src={svgFacebook} alt="Subscribe to CryptoMoto Facebook Group" />
                    <span>Facebook Group</span>
                  </a>
                </li>
                <li>
                  <a title="Subscribe to CryptoMoto Telegram Chat" target="_blank" rel="noopener" href="https://t.me/cryptomotochat">
                    <img src={svgTelegram} alt="Subscribe to CryptoMoto Telegram Chat" />
                    <span>Telegram Chat</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>                    
      </footer>
    </div>
  );
}


/*
function App() {

  const [token, setToken] = useState();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div className="App">
      <ArticleList />
      <BrowserRouter>
        <Routes>
          <Route path="/" />
          <Route path="/Dashboard" element={<Dashboard/>} />
          <Route path="/Preferences" element={<Preferences/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
*/