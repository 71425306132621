import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import User from '../User/User';
import WebSock from './WebSocket';

const GET_PublicChats = gql`
  query PublicChats($filter: ChannelArgsInput) {
    publicChats(Filter: $filter) {
      image {
        filename
        downloadUrl
        id
      }
      id
      title
      description
      parentID
    }
  }
`;

const Flood_Channel = gql`
  query Channel($channelId: ID!) {
    channel(id: $channelId) {
      title
      description
      number_of_unread_message_in_channel
    }
  }
`;

function DisplayPublicChats() {

  var parentIDget = 0;
  const queryParameters = new URLSearchParams(window.location.search);
  parentIDget = queryParameters.get("pnt");

  //console.log(parentIDget);
  
  const { loading, error, data } = useQuery(GET_PublicChats, {variables:
    {filter: {
      parentID: 0,
    }}
  }, {pollInterval: 500000});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return data.publicChats.map(({ id, title, image, description, parentID }) => (
    <div key={id}>
        {image.filename}
        <img src='{image.downloadUrl}' alt='' />
      <h3><a href={`/public-chats?pnt=${id}`}>{title}</a></h3>
      <div>{description}</div>
      <User key={id} 
          id={parentIDget}
      />
      <hr></hr>
      <hr></hr>
    </div>
  ));
}

function DisplayChannel() {

  var channelID = 5;
  
  const { loading, error, data } = useQuery(Flood_Channel, {variables:
    {channelId: channelID}
  }, {pollInterval: 50000});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div>
      <h3>{data.channel.title} ({data.channel.number_of_unread_message_in_channel})</h3>
      <div>{data.channel.description}</div>
      <hr></hr>
      <hr></hr>
    </div>
  );
}

const PublicChats = () => {

  const [value, setValue] = useState(''); // state of input


  return (
    <section>
      <DisplayChannel />
      <div className='form'>
          <input 
              type='text' 
              value={value} 
              onChange={e => setValue(e.target.value)} 
          />
          <button>Send</button>
      </div>
      <br></br>
      <WebSock />
      <nav>
        <ul>
          <li><Link to="/chats">Private Chats</Link></li>
          <li><Link to="/public-chats">Public Chats</Link></li>
          <li><Link to="#">W-Talkie</Link></li>
        </ul>
      </nav>
      <DisplayPublicChats />
    </section>
  );

};

export default PublicChats;