import React from 'react';
import { useQuery, gql } from '@apollo/client';
import './ServiceList.css';
import { AUTH_TOKEN } from '../../constants';
import Login from '../Login/Login';


const GET_SERVICES = gql`
  query Mobjects {
    mobjects {
      id
      title
      listing {
        name
      }
      photo {
        downloadUrl
      }
      description
      country {
        id
        nicename
      }
      city
      address
      lat
      lng
      phone
      publicEmail
      website
      workingHours
      rating
    }
  }
`;

function DisplayLocations() {
  const { loading, error, data } = useQuery(GET_SERVICES, {pollInterval: 500000});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return data.mobjects.map(({ id, title, listing, city, country, photo, rating, address, workingHours, description }) => (
    <div className='article-item' key={id}>
      <img src={`${photo.downloadUrl}`} alt={`${title}`} />
      <h3>{title} ({rating})</h3>
      <h4>{listing.name}</h4>
      <p>{address}</p>
      {workingHours ? <p>{workingHours}</p>: ""}
      <p>{description}</p>
      <p><strong>Contacts</strong>: <a>Find out in CryptoMoto app 👆</a></p>
    </div>
  ));
}


const ServiceList = () => {
  /*
  const authToken = localStorage.getItem(AUTH_TOKEN);
  //console.log(authToken);

  return (
    <>
    {authToken ? (
      <DisplayLocations />
    ) : (
      <Login />
    )}
    </>
  );
  */

    return(
        <section>
          <DisplayLocations />
        </section>
    );
};

export default ServiceList;