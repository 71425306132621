import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { AUTH_TOKEN } from '../../constants';

const Login = () => {

    const navigate = useNavigate();
    const [formState, setFormState] = useState({
        login: true,
        user: {
            email: "",
            password: "",
        },
        activateUserId: 0,
        code: 0,
        AUTH_TOKEN: '',
    });


    const SIGNUP_MUTATION = gql`
        mutation Register($user: UserCreateInput!) {
            register(user: $user) {
                ... on LoginUserResponse {
                    id
                }
            }
        }
    `;

    const CODE_CONFIRMATION = gql`
        mutation ActivateUser($activateUserId: ID!, $code: Int!) {
            activateUser(id: $activateUserId, code: $code) {
                ... on AccessToken {
                    token
                }
            }
        }    
    `;

    const LOGIN_MUTATION = gql`
        mutation Login($user: UserCreateInput!) {
            login(user: $user) {
                ... on AccessToken {
                    token
                }
            }
        }
    `;

    const [login] = useMutation(LOGIN_MUTATION, {
        variables: {
            user: {
                email: formState.email,
                password: formState.password
            },
        },
        onCompleted: ({ login }) => {
            //const tokenAUTH = "Bearer "+login.token;
          localStorage.setItem(AUTH_TOKEN, login.token);
          navigate('/');
        }
    });
    
    const [signup] = useMutation(SIGNUP_MUTATION, {
        variables: {
            user: {
                email: formState.email,
                password: formState.password
            },
        },
        onCompleted: ({ register }) => {
          //localStorage.setItem(AUTH_TOKEN, signup.token);
          navigate('/login?check='+register.id);
        }
    });

    const queryParameters = new URLSearchParams(window.location.search);
    const checkCode = queryParameters.get("check");

    const [confirmation] = useMutation(CODE_CONFIRMATION, {
        variables: {
            activateUserId: checkCode,
            code: formState.code,
        },
        onCompleted: ({ activateUser }) => {
          localStorage.setItem(AUTH_TOKEN, activateUser.token);
          navigate('/create?token='+activateUser.token);
        }
    });


  return (
    <div>
      <h4>
        {formState.login ? 'Login' : 'Sign Up'}
      </h4>
      <div>
        {checkCode ? (
            <div>
                <input
                    value={formState.code}
                    onChange={(e) =>
                        setFormState({
                        ...formState,
                        code: e.target.valueAsNumber
                        })
                    }
                    type="number"
                    placeholder="Your code"
                />
                <button
                    onClick={confirmation}
                >
                    Confirmation
                </button>
            </div>
        ) : (
        <div>
            <input
            value={formState.email}
            onChange={(e) =>
                setFormState({
                ...formState,
                email: e.target.value
                })
            }
            type="email"
            placeholder="Your email address"
            />
            <input
            value={formState.password}
            onChange={(e) =>
                setFormState({
                ...formState,
                password: e.target.value
                })
            }
            type="password"
            placeholder="Choose a safe password"
            />
            <div>
                <button
                onClick={formState.login ? login : signup}
                >
                {formState.login ? 'login' : 'create account'}
                </button>
                <button
                onClick={(e) =>
                    setFormState({
                    ...formState,
                    login: !formState.login
                    })
                }
                >
                {formState.login
                    ? 'need to create an account?'
                    : 'already have an account?'}
                </button>
            </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Login;