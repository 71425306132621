import React from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_UserFriends = gql`
  query Friends($userId: ID!) {
    friends(user_id: $userId) {
      first_name
      last_name
      id
    }
  }
`;

const Friends = (props) => {
    const userId = props.user_id;
    console.log(userId);
    const { loading, error, data } = useQuery(GET_UserFriends, {variables: {userId: userId}});

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;
  
    return data.friends.map(({ id, first_name, last_name }) => (
      <li key={id}>
        <a href={`/profile?user=${id}`}>{first_name} {last_name}</a>
      </li>
    ));
}
  
export default Friends;
  