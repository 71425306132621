import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_TOKEN } from '../../constants';

const Header = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  //console.log(authToken);
  const navigate = useNavigate();

  return (
    /*
      <></>
      <Link to="/search">Search</Link>
      <Link to="/create">Create</Link>      
      <Link to="/login">Login/Signup</Link>
    */
    <div className='header-nav'>     
        {authToken && (
          <div className='left-menu'>
            <Link to="/">Feed</Link>
            <Link to="/services">Services</Link>
          </div>
        )}
        {authToken ? (
          <div
            className='logout'
            onClick={() => {
              localStorage.removeItem(AUTH_TOKEN);
              navigate(`/`);
            }}
          >
            Logout
          </div>
        ) : (
          <div className='left-menu'>
            <Link to="/">Feed</Link>
            <Link to="/services">Services</Link>
          </div>
        )}

        {authToken ? (
          <div>
            <ul>
              <li><Link to="/profile">Profile</Link></li>
              <li><Link to="/users">Users</Link></li>
              <li><Link to="/chats">Private Chats</Link></li>
              <li><Link to="/public-chats">Public Chats</Link></li>
              <li><Link to="/menu">Settings</Link></li>
            </ul>
          </div>
        ) : (
          <div></div>
        )}
    </div>
  );
};

export default Header;