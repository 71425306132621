import React from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_UserPosts = gql`
  query Articles($articlesArgs: ArticlesArgsInput) {
    articles(ArticlesArgs: $articlesArgs) {
      title
      content
      id
    }
  }
`;

const UserArticles = (props) => {
  //const { article } = props;
  const { loading, error, data } = useQuery(GET_UserPosts, {variables:
    {articlesArgs: {
      author_id: props.user_id,
    }}
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return data.articles.map(({ id, title, content }) => (
    <div key={id}>
      <h3>{title}</h3>
      <p>{content}</p>
      <hr></hr>
    </div>
  ));
};

export default UserArticles;