import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

const CREATE_LINK_MUTATION = gql`
  mutation CreateImage($image: ImageCreateInput!) {
    createImage(image: $image) {
      presignUrl
    }
  }
`;

export default function ImageUpload() {

    //const form = document.getElementById("form");

    function submitForm() {

        const files = document.getElementById("files");
        //const formData = new FormData();
        //formData.append("name", name.value);
        /*
        for(let i =0; i < files.files.length; i++) {
                formData.append("files", files.files[i]);
        }
        */
       console.log(files.files[0]);
        //formData.append("fileName", files.files[0]);
    
        let bodyData = {
            fileName: files.files[0].name,
            fileType: files.files[0].type
        }       

        const requestObject = {
            method:'POST',
            headers:{
              'content-type': 'application/json'
            },
            body: JSON.stringify(bodyData)
        }

        fetch('http://localhost/backend', requestObject)
          .then(res => {
              console.log(res);
              fetch("link", {
                  method:'PUT',
                  body :files.files[0]
              }).then((res) => {
                console.log(res)
              })
        })

        /*
        fetch("http://localhost/backend/upload", {
            method: 'POST',
            body: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            }
        })
            .then((res) => console.log(res))
            .catch((err) => ("Error occured", err));
        */
    }

    function setValues(e)
    {
      const imageFile = document.getElementById("image");
      console.log(imageFile.files[0]);
      document.getElementById("title_name").value = imageFile.files[0].name;
      document.getElementById("preview_size").value = imageFile.files[0].size;
      document.getElementById("content_type").value = imageFile.files[0].type;
    }


    const [formState, setFormState] = useState({
      image: {
          filename: "Test",
          size: 1,
          extension: "",
      },
    });   
  
    const [createLink] = useMutation(CREATE_LINK_MUTATION, {
      variables: {
        image: {
          filename: formState.title,
          size: formState.preview,
          extension: formState.content,
        },
      },
      onCompleted: ({createImage}) => 
        console.log(createImage.presignUrl)
    });    

    return (
      <div >
        <h1>Image Upload</h1>
        <form
          onSubmit={(e) => {
              e.preventDefault();
              submitForm();
          }}
        >
            <div className="input-group">
                <label htmlFor='files'>Select files</label>
                <input id='files' type="file" name='file' />
            </div>
            <button className="submit-btn" type='submit'>Upload</button>
        </form>
        <h2>The try #endless</h2>
        <form
        onSubmit={(e) => {
            e.preventDefault();
            createLink();
        }}
      >
        <input id='image' type="file" name='image' onChange={setValues} />
        <div className="flex flex-column mt3">
          <input
            id="title_name"
            name='title'
            value={formState.title}
            onChange={(e) =>
              setFormState({
                ...formState,
                title: e.target.value
              })
            }
            type="text"
            placeholder="Title of the article"
          />
          <input
            id="preview_size"
            value={formState.preview}
            name='preview'
            onChange={(e) =>
              setFormState({
                ...formState,
                preview: parseInt(e.target.value)
              })
            }
            type="number"
            placeholder="Content of the article" />
            <textarea name="content" id="content_type" cols="30" rows="10"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  content: e.target.value
                })
              }            
            >{formState.preview}</textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      </div>
    );
}