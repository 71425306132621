import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

// getting current user id
var currentUserId = 0;
/*
const GET_CURRENT_PROFILE = gql`
  query Myprofile($token: String!) {
    myprofile(token: $token) {
      id
    }
  }
`;
function DisplayProfile() {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const { data } = useQuery(GET_PROFILE, {variables: { token: authToken }});
  currentUserId = data.myprofile.id;

  return (
    <div>
        <h6>{currentUserId}</h6>
    </div>
  );
}
*/

const CREATE_LINK_MUTATION = gql`
  mutation CreateArticle($article: ArticleCreateInput!) {
    createArticle(article: $article) {
      ... on Article {
        id
      }
    }
  }
`;

const CreateArticle = () => {
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    article: {
        title: "Test article",
        preview: "Text preview",
        image: "b2bdbd1c-d1de-4d4b-ab7d-1f66e09eada0",
        content: "test content, creating article",
        categories: [1],
        author: 1,
      },
  });

  const [createLink] = useMutation(CREATE_LINK_MUTATION, {
    variables: {
        article: {
            title: formState.title,
            preview: formState.preview,
            content: formState.content,
            image: "9988de07-111e-4627-946c-2d431eadab6e",
            categories: [1],
            author: currentUserId,
          },
    },
    onCompleted: () => navigate('/')
  });

  return (
    <div>
      <form
        onSubmit={(e) => {
            e.preventDefault();
            createLink();
        }}
      >
        <div className="flex flex-column mt3">
          <input
            name='title'
            value={formState.title}
            onChange={(e) =>
              setFormState({
                ...formState,
                title: e.target.value
              })
            }
            type="text"
            placeholder="Title of the article"
          />
          <input
            value={formState.preview}
            name='preview'
            onChange={(e) =>
              setFormState({
                ...formState,
                preview: e.target.value
              })
            }
            type="text"
            placeholder="Content of the article" />
            <textarea name="content" id="" cols="30" rows="10"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  content: e.target.value
                })
              }            
            >{formState.preview}</textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CreateArticle;