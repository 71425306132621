import React from 'react';
import { Link } from 'react-router-dom';

const Menu = (props) => {

    return (
      <div>
        <h1>Account</h1>

        <h2>Profile Settings</h2>
        <ul>
          <li><Link to="/friend-requests">Friend requests</Link></li>
          <li><Link to="/">Enable tracking</Link></li>
          <li><Link to="/">Visibility</Link></li>
        </ul>

        <h2>App Settings</h2>
        <ul>
          <li><Link to="/">Email address</Link></li>
          <li><Link to="/">Language</Link></li>
          <li><Link to="/">Share</Link></li>
        </ul>

        <h2>CryptoMoto</h2>
        <ul>
          <li><Link to="/">Support</Link></li>
          <li><Link to="/">Give thanks</Link></li>
          <li><a href='https://cryptomoto.org/terms-of-use/' target={'_blank'} rel="noreferrer">Terms of use</a></li>
        </ul>
    </div>
    )
}

export default Menu;