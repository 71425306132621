import React from 'react';
import { AUTH_TOKEN } from '../../constants';
import { useQuery, gql } from '@apollo/client';
import './ArticleList.css';
import Login from '../Login/Login';


const GET_ARTICLES = gql`
  query GetArticles {
    articles{
      id
      title
      preview
      author {
        first_name
        last_name
      }
      image {
        downloadUrl
      }
    }
  }
`;

function DisplayLocations() {
  const { loading, error, data } = useQuery(GET_ARTICLES, {pollInterval: 500000});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return data.articles.map(({ id, title, preview, author, image }) => (
    <div className='article-item' key={id}>
      <img src={`${image.downloadUrl}`} alt={`${title}`} />
      <h3>{title}</h3>
      <h5>Posted by {author.first_name} {author.last_name}</h5>
      <p>{preview}</p>
    </div>
  ));
}


const ArticleList = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  console.log(authToken);

  return (
    <>
    {authToken ? (
      <DisplayLocations />
    ) : (
      <Login />
    )}
    </>
  );

};

export default ArticleList;