import React from 'react';
import { useQuery, gql } from '@apollo/client';
import LazyLoad from 'react-lazyload';
import './ArticleWebList.css';
import right from './right.svg';
import SVGviews from './views.svg';
import SVGlike from './like.svg';
import SVGdislike from './dislike.svg';
import SVGcomment from './comment.svg';


const GET_ARTICLES_WEB = gql`
  query ArticlesWeb {
    articlesWeb {
      id
      image {
        downloadUrl
        extension
      }
      title
      author {
        avatar {
          downloadUrl
        }
        first_name
        last_name
        accountLevel
        city
        country {
          nicename
        }
      }
      content
      views
      likes {
        id
      }
      dislikes {
        id
      }
      comments {
        id
      }
    }
  }
`;

const AccountLevel = (props) => {
  var accLevelMeaning = "Couch Biker";
  if(props.accountLevel == 1) {
    accLevelMeaning = "Newbie";
  } else if(props.accountLevel == 2) {
    accLevelMeaning = "Standard";
  } else if(props.accountLevel == 3) {
    accLevelMeaning = "Expert";
  } else if(props.accountLevel == 4) {
    accLevelMeaning = "Old-timer biker";
  } else if(props.accountLevel == 5) {
    accLevelMeaning = "Maniac";
  }
  return accLevelMeaning;
};

const UserLocation = (props) => {
  var traveler = "Traveler";
  if(props.city && props.country ) {
    traveler = `${props.city}, ${props.country}`;
  } else if(props.city && !props.country ) {
    traveler = `${props.city}`;
  } else if(!props.city && props.country ) {
    traveler = `${props.country}`;
  }
  return traveler;
};

function DisplayFeed() {
  const { loading, error, data } = useQuery(GET_ARTICLES_WEB, {pollInterval: 500000});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return data.articlesWeb.map(({ id, title, content, author, accountLevel, city, country, image, views, likes, dislikes, comments }) => (
    <div className='article-item' key={id}>
      <div className='author'>
        <div className='avatar'>
          <LazyLoad>
            <img src={`${author.avatar.downloadUrl}`} alt={author.first_name} />
          </LazyLoad>
        </div>
        <div className='title'>
          <h4>{author.first_name} {author.last_name}</h4>
          <div className='level'>
            <div><AccountLevel accountLevel={author.accountLevel} /></div>
            <img src={right} alt="Motorcycle App CryptoMoto" />
            <div><UserLocation city={author.city} country={author.country?author.country.nicename:''} /></div>
          </div>
        </div>
      </div>
      <LazyLoad>
        <a href={`/post/${id}`}>
          <img src={image.downloadUrl} alt={title} className='post-image' style={{maxHeight:300,width:"auto"}} />
        </a>
      </LazyLoad>
      <div className='like-row'>
        <div>
          <img src={SVGviews} alt="How many people viewed this post" />
          {views}
        </div>
        <div>
          <img src={SVGlike} alt="How many people liked this post" />
          {likes.length}
        </div>
        <div>
          <img src={SVGdislike} alt="How many people didn't like this post" />
          {dislikes.length}
        </div>
        <div>
          <img src={SVGcomment} alt="How many people commented this post" />
          {comments.length}
        </div>
      </div>
      <h3><a href={`/post/${id}`}>{title}</a></h3>
      {/*<div className='content'>{content}</div>*/}
    </div>
  ));
}

const ArticleWebList = () => {
  return (
    <>
      <h2>CryptoMoto Posts Feed</h2>
      <DisplayFeed />
      <h2>More Posts in CryptoMoto App. Download now! 👆</h2>
    </>
  );
};

export default ArticleWebList;