import React from 'react';
import { useQuery, gql } from '@apollo/client';
import User from '../User/User';

const GET_USERS = gql`
    query Users {
        users {
            first_name
            last_name
            id
            avatar {
                id
                downloadUrl
            }
            accountLevel
            city
            country {
                name
            }
            friendshipStatus
        }
    }
`;

function DisplayLocations() {
  const { loading, error, data } = useQuery(GET_USERS, {pollInterval: 500000});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return data.users.map(({ id, first_name, last_name, avatar, accountLevel, city, country, friendshipStatus }) => (
    <div key={id}>
        {avatar.id}
        <img src='{avatar.downloadUrl}' alt='' />
      <h2>{first_name} {last_name} - {id}</h2>
      <div>{accountLevel} - {city?city+', ':''}{country?country.name:''}</div>
      <User key={id} 
          id={id}
          friendshipStatus={friendshipStatus}
      />
      <hr></hr>
      <hr></hr>
    </div>
  ));
}


const Users = () => {

  return (
    <DisplayLocations />
  );

};

export default Users;