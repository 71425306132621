import React, { useRef, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const GET_MESSAGES = gql`
  query Messages($chatId: ID!) {
    messages(chat_id: $chatId) {
      chat {
        id
      }
      author {
        first_name
      }
      text
    }
  }
`;

const CREATE_MESSAGE = gql`
  mutation CreateMessage($message: MessageCreateInput!) {
    createMessage(message: $message) {
      code
      id
      message
    }
  }
`;

const Chats = () => {

  const navigate = useNavigate();

  var parentChat = 0;
  const queryParameters = new URLSearchParams(window.location.search);
  parentChat = queryParameters.get("cht");
  console.log(parentChat);

  const [value, setValue] = useState(''); // state of input

  // function send message
  const sendMessage = async () => {
    sendMessageMut();
    /*
      const message = {
          username,
          message: value,
          id: Date.now(),
          event: 'message'
      }
      socket.current.send(JSON.stringify(message));
      */
      setValue('');
  };

  const [sendMessageMut] = useMutation(CREATE_MESSAGE, {
    variables: {
      message: {
        chat: parentChat,
        text: value,
      },
    },
    onCompleted: () => navigate('/chats?cht='+parentChat)
  });

  const { loading, error, data } = useQuery(GET_MESSAGES, {variables: {chatId: parentChat}, pollInterval: 5000});
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <section>
      <br></br>
      <div className='form'>
          <input 
              type='text' 
              value={value} 
              onChange={e => setValue(e.target.value)} 
          />
          <button onClick={sendMessage}>Send</button>
      </div>
      <br></br>
      <div className="messages">
          {data.messages.map(mess =>
              <div className="message" key={mess.id}>
                  {mess.event === 'connection'
                      ? <div className='new-user'>New user {mess.username} is entered the chat.</div>
                      : <div><b>{mess.author.first_name}</b>: {mess.text}</div>
                  }
              </div>
          )}
      </div>
    </section>
  );
};

export default Chats;