import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { AUTH_TOKEN } from '../../constants';
import ImageUpload from '../ImageUpload/ImageUpload';
import Friends from '../Friends/Friends';
import UserArticles from '../UserArticles/UserArticles';


const queryParameters = new URLSearchParams(window.location.search);
const checkUser = queryParameters.get("user");
var currentUserId = 0;


const GET_CURRENT_PROFILE = gql`
  query Myprofile($token: String!) {
    myprofile(token: $token) {
      id
    }
  }
`;
function DisplayCurrentProfile() {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const { loading, error, data } = useQuery(GET_CURRENT_PROFILE, {variables: { token: authToken }});

  console.log(authToken);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  currentUserId = data.myprofile.id;

  return (
    <div>
        <h6>{currentUserId}</h6>
    </div>
  );
}


const GET_PROFILE = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      email
      first_name
      last_name
      avatar {
        downloadUrl
      }
      points
      accountLevel
      friendCount
      followerCount
    }
  }
`;
function DisplayProfile() {

  var selectedUserID = 0;

  if( checkUser ) {
    selectedUserID = checkUser;
  }else {
    selectedUserID = currentUserId;
  }
  
  const { loading, error, data } = useQuery(GET_PROFILE, {pollInterval: 500000, variables: { userId: selectedUserID }});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div>
        <img src={`${data.user.avatar.downloadUrl}`} alt={`${data.user.first_name} ${data.user.last_name}`} />
        <h3>
          {data.user.first_name} {data.user.last_name}
        </h3>
        <div>My Points: {data.user.points}</div>
        <div>Account Level: {data.user.accountLevel}</div>
        <h2>Friends: {data.user.friendCount}</h2>
        <ul>
          <Friends key={data.user.email} user_id={data.user.id} />
        </ul>
        <h3>Followers: {data.user.followerCount}</h3>
        <h2>My Posts</h2>
        <UserArticles user_id={data.user.id} />
    </div>
  );
}

const Profile = () => {
  return(
    <div>
      <h1>My Profile {checkUser}</h1>
      <ImageUpload />
      <DisplayCurrentProfile />
      <DisplayProfile />
    </div>
  );
}

export default Profile;
