import React from 'react';
import { useMutation, gql } from '@apollo/client';


const FriendReqOut = (props) => {

    const Change_Friend_Status = gql`
        mutation ChangeFriendshipStatus($changeFriendshipStatus: ChangeFriendshipStatusInput!) {
            changeFriendshipStatus(changeFriendshipStatus: $changeFriendshipStatus) {
                code
                message
            }
        }
    `;

    const [handleClick] = useMutation(Change_Friend_Status, {
        variables: { changeFriendshipStatus: { user_id: props.id }
        },
        onCompleted: ({ changeFriendshipStatus }) => {
            console.log( changeFriendshipStatus.message );
        }
    });


    return (
    <div>
      <button onClick={handleClick}>Cancel</button>
    </div>
  );
};

export default FriendReqOut;
