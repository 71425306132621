import React from 'react';
import ReactDOM from 'react-dom/client';

import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { setContext } from '@apollo/client/link/context';


import { AUTH_TOKEN } from './constants'; // not secure


import './index.css';
//import reportWebVitals from './reportWebVitals';


// http link of frontend server
const httpLink = createHttpLink({
  uri: 'https://cryptomotoapp.com/backend',
  credentials: 'same-origin'
});

const wsLink = new GraphQLWsLink(createClient({
  url: 'wss://cryptomotoapp.com/backend',
}));

// Header authentication
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});


// The split function takes three parameters:
// use wsLink for the truthy values which is subscriptions. For others use httpLink
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
);


// Apollo Client
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

// root and render
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
