import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { AUTH_TOKEN } from '../../constants';
import FriendReq from '../FriendReq/FriendReq';
import FriendReqOut from '../FriendReqOut/FriendReqOut';

var currentUserId = 0;

const GET_CURRENT_PROFILE = gql`
  query Myprofile($token: String!) {
    myprofile(token: $token) {
      id
    }
  }
`;
function DisplayCurrentProfile() {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const { loading, error, data } = useQuery(GET_CURRENT_PROFILE, {variables: { token: authToken }});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  currentUserId = data.myprofile.id;

  return (
    <div>
        <h6>{currentUserId}</h6>
    </div>
  );
}

// Incoming friend requests
const GET_UserFriends = gql`
  query FriendRequests {
    friendRequests {
      id
      first_name
      last_name
      avatar {
        filename
      }
      createdAt
    }
  }
`;
function DisplayProfile() {

  const { loading, error, data } = useQuery(GET_UserFriends, {pollInterval: 500000, variables: { userId: currentUserId }});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  

  return data.friendRequests.map(({ id, first_name, last_name, avatar, createdAt }) => (
    <div key={id}>
      <div>img: {avatar.filename}</div>
      <h4>{first_name} {last_name}</h4>
      <p>{createdAt}</p>
      <FriendReq key={id} id={id} />
      <hr></hr>
    </div>
  ));
}

// Outgoing friend requests
const GET_OutFriendReqs = gql`
  query OutgoingFriendRequests {
    outgoingFriendRequests {
      id
      first_name
      last_name
      avatar {
        filename
      }
      createdAt
    }
  }
`;
function OutgoingFriendReqs() {
  const { loading, error, data } = useQuery(GET_OutFriendReqs, {pollInterval: 500000, variables: { userId: currentUserId }});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  

  return data.outgoingFriendRequests.map(({ id, first_name, last_name, avatar, createdAt }) => (
    <div key={id}>
      <div>{avatar.filename}</div>
      <h4>{first_name} {last_name}</h4>
      <p>{createdAt}</p>
      <FriendReqOut key={id} id={id} />
      <hr></hr>
    </div>
  ));
}

const FriendRequests = () => {
  
  return(
    <div>
      <DisplayCurrentProfile />
      <h1>Friend requests</h1>
      <h2>Incoming</h2>
      <DisplayProfile />
      <h2>Outgoing</h2>
      <OutgoingFriendReqs />
    </div>
  );
}

export default FriendRequests;
