import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import LazyLoad from 'react-lazyload';
import right from './right.svg';
import SVGviews from './views.svg';
import SVGlike from './like.svg';
import SVGdislike from './dislike.svg';
import SVGcomment from './comment.svg';


const GET_ARTICLE = gql`
  query Article($articleId: ID!) {
        article(id: $articleId) {
            title
            content
            createdAt
            image {
                downloadUrl
            }
            author {
                first_name
                last_name
                avatar {
                    downloadUrl
                }
                accountLevel
                city
                country {
                  nicename
                }
            }
            views
            likes {
              id
            }
            dislikes {
              id
            }
            comments {
              id
            }     
        }
    }
`;

const AccountLevel = (props) => {
    var accLevelMeaning = "Couch Biker";
    if(props.accountLevel == 1) {
      accLevelMeaning = "Newbie";
    } else if(props.accountLevel == 2) {
      accLevelMeaning = "Standard";
    } else if(props.accountLevel == 3) {
      accLevelMeaning = "Expert";
    } else if(props.accountLevel == 4) {
      accLevelMeaning = "Old-timer biker";
    } else if(props.accountLevel == 5) {
      accLevelMeaning = "Maniac";
    }
    return accLevelMeaning;
};
  
const UserLocation = (props) => {
    var traveler = "Traveler";
    if(props.city && props.country ) {
      traveler = `${props.city}, ${props.country}`;
    } else if(props.city && !props.country ) {
      traveler = `${props.city}`;
    } else if(!props.city && props.country ) {
      traveler = `${props.country}`;
    }
    return traveler;
};


const BlogPost = () => {

    const { postId } = useParams();

    // Getting data
    const { loading, error, data } = useQuery(GET_ARTICLE, {variables: {articleId: postId}});

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;

  return (
    <div className='article-item'>
        <h2>{data.article.title}</h2>
        <div className='author'>
            <div className='avatar'>
                <LazyLoad>
                    <img src={data.article.author.avatar.downloadUrl} alt={data.article.author.first_name} />
                </LazyLoad>
            </div>
            <div className='title'>
                <h4>{data.article.author.first_name} {data.article.author.last_name}</h4>
            <div className='level'>
                <div><AccountLevel accountLevel={data.article.author.accountLevel} /></div>
                <img src={right} alt="Motorcycle App CryptoMoto" />
                <div>
                  <UserLocation city={data.article.author.city} country={data.article.author.country ? data.article.author.country.nicename : ''} />
                </div>
            </div>
            </div>
        </div>
        <LazyLoad>
            <img src={data.article.image.downloadUrl} alt={data.article.title} className='post-image' />
        </LazyLoad>
        <div className='content' style={{padding:'20px 0'}}>{data.article.content}</div>
        <div className='like-row'>
            <div>
              <img src={SVGviews} alt="How many people viewed this post" />
              {data.article.views}
            </div>
            <div>
              <img src={SVGlike} alt="How many people liked this post" />
              {data.article.likes.length}
            </div>
            <div>
              <img src={SVGdislike} alt="How many people didn't like this post" />
              {data.article.dislikes.length}
            </div>
            <div>
              <img src={SVGcomment} alt="How many people commented this post" />
              {data.article.comments.length}
            </div>
        </div>
    </div>
  )
}

export default BlogPost;