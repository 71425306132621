import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { AUTH_TOKEN } from '../../constants';
import { useNavigate } from 'react-router-dom';
//import PropTypes from 'prop-types';

// getting current user id
const GET_PROFILE = gql`
  query Myprofile($token: String!) {
    myprofile(token: $token) {
      id
    }
  }
`;
var currentUserId = 0;
function DisplayProfile() {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const { loading, error, data } = useQuery(GET_PROFILE, {variables: { token: authToken }});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  currentUserId = data.myprofile.id;

  return (
    <div>
        <h6>{currentUserId}</h6>
    </div>
  );
}


const User = (props) => {
  //const { user } = props;
    
    var thisiscurrent = false;
    if( currentUserId === props.id ) thisiscurrent = true;

    const navigate = useNavigate();

    var friendStatus = 'Follow';
    var iamsubscriber = 0;
    var wearefriends = 0;

    if( props.friendshipStatus && props.friendshipStatus === 1 )
    {
        friendStatus = 'Profile';
        iamsubscriber = 1;
    }
    else if( props.friendshipStatus && props.friendshipStatus === 2 )
    {
        friendStatus = 'Follow Back';
    }
    else if( props.friendshipStatus && props.friendshipStatus === 3 )
    {
        friendStatus = 'Message';
        wearefriends = 1;
    }

    /*
    const handleClick1 = (event) => {

        event.preventDefault();
        this.attr('disabled', 'disabled');
        console.log( props.id );
        
        const tickerURL = `https://api.coinpaprika.com/v1/tickers/${valueChangeId}`;
        const response = await axios.get(tickerURL);
        //debugger;
        const newPrice = response.data.quotes['USD'].price;
        const newCoinData = coinData.map( function(values) {
        let newValues = {...values};
        if( valueChangeId === values.key ){
            newValues.price = newPrice;
        }
        return newValues;
        });
        
        setCoinData(newCoinData);
    }
    */

    const Change_Friend_Status = gql`
        mutation ChangeFriendshipStatus($changeFriendshipStatus: ChangeFriendshipStatusInput!) {
            changeFriendshipStatus(changeFriendshipStatus: $changeFriendshipStatus) {
                code
                message
            }
        }
    `;

    const [handleClick] = useMutation(Change_Friend_Status, {
        variables: { changeFriendshipStatus: {
                user_id: props.id 
            }
        },
        onCompleted: ({ changeFriendshipStatus }) => {
            console.log( changeFriendshipStatus.message );
        }
    });


  return (
    <div>
        <DisplayProfile />
        {thisiscurrent ? (
          <button
            onClick={() => {navigate(`/profile`)}}
          >
            My profile
          </button>
        ) : (
            <>
                {iamsubscriber ? (
                    <a href={`/profile?user=${props.id}`}>
                      {friendStatus}
                    </a>
                ) : (
                  <>
                    {wearefriends ? (
                        <button
                            onClick={() => {navigate(`/?chatwith=${props.id}`)}}
                        >
                            {friendStatus}
                        </button>
                    ) : (
                        <button onClick={handleClick}>{friendStatus}</button>
                    )}
                  </>
                )}
            </>
        )}
    </div>
  );
};

export default User;
